@import "../../../stylesheets/constants.scss";

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 2em;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 5px #f1f1f1;

  .error-message {
    color: red;
    font-weight: bolder;
    padding: 1rem;
    text-align: center;
  }
  .success-message {
    color: green;
    font-weight: bolder;
    padding: 1rem;
    text-align: center;
  }

  .loader{
    color: var(--theme-color-1);
  }

  .loading {
    margin: 0;
    font-size: 1rem;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 1s infinite;
      animation: ellipsis steps(4, end) 1s infinite;
      content: "\2026";
      /* ascii code for the ellipsis character */
      width: 0px;
    }

  }

  a {
    color: black;
  }

  .sign-in-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: .5rem;
    padding-left: .5rem;
    margin-bottom: 1rem;
    text-align: center;
    border-radius: 2rem;
    width: 100%;
    max-width: 280px;
    background-color: white;
    color: black;
    box-shadow: 0px 0px 5px 1px #e1e1e1;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    outline-width: 0;

    &:hover {
      border-color: $inputs-border-color;
      cursor: pointer;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    // width: 80%;

    .logo-container {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // padding: 2em;
      // margin-bottom: 2em;

      img.logo {
        height: auto;
        width: 100%;
        margin-bottom: 1rem;
      }

      img.wordmark {
        height: auto;
        width: 100%;
        margin-bottom: 3rem;
      }
    }

    .input {
      width: 100%;
      position: relative;
      display: flex;

      &.name-container {
        width: calc(50%-5px);

        &:first-of-type {
          margin-right: 5px;
        }

        &:last-of-type {
          margin-left: 5px;
        }
      }

      .astersik {
        position: absolute;
        right: 5px;
        color: red;
        font-size: .7rem;
      }
    }

    input[type="email"],
    input[type="password"],
    input[type="text"] {
      border-width: 0px 0px 2px 0px;
      border-color: black;
      background-color: transparent;
      margin-bottom: 1.5rem;
      width: 100%;

      &:focus {
        border: 0px solid black;
        outline: none;
        box-shadow: 0px 0px 0px white;
        border-width: 0px 0px 2px 0px;
      }
    }

  }

  .continue-with-account {
    visibility: hidden;
    font-size: 13px;
  }

  .links-section {
    text-align: center;
    font-size: 15px;

    .link-ecosystem {
      color: $inputs-border-color;

      &:hover {
        cursor: pointer;
        text-decoration-line: none;
      }
    }

    .link {
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }

    .seperation-line {
      // width: 250px;
      border: 1px solid #c1c1c1;
      margin-top: 2em;
      margin-bottom: 2em;
    }
  }
}