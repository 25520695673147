.top-nav-rhs {
  a, div {
    cursor: pointer;
    margin-left: 0.5rem;

    p {
      font-size: 0.7rem;
      text-align: center;
      color: var(--theme-color-1);
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.navbar {
  padding: 0rem;
}

.navbar-toggler {
  padding: 0rem;

  &:focus {
    outline: none;
    border: 2px var(--theme-color-1) solid;
  }
}

.step-number-completed>svg {
  fill: var(--theme-color-1);
}
.theme-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 1.87rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

// .naya-logo {
//   filter: invert(50%);
// }

@media (min-width: 300px) {

  .content-container {
    position: relative;
    z-index: 0;
    margin-top: 5rem;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    min-height: 100vh;

    // position: absolute;
    margin-top: 3rem;
    z-index: 1;
    width: 100%;
  }

  .nav {
    position: fixed;
    padding: 0 !important;
    top: 2.35rem;
    right: 2rem;
    z-index: 1030;
  }

  .naya-logo {
    position: fixed;
    top: 2.5rem;
    left: 2rem;
    z-index: 1030;
  }

  .top-nav-rhs {
    position: fixed;
    top: 2rem;
    right: 5rem;
    z-index: 1030;

    a {
      display: block;
    }
  }
}

@media (min-width: 450px) {

  .content-container {
    margin-top: 8rem;
    flex-direction: column;
  }
  .main-content {
    flex: 1;
    min-height: 100vh;
    padding-top: 0;
  }

  .top-nav-rhs {
    top: 2rem;
    right: 5rem;
  }
}

@media (min-width: 799px) {

  .content-container {
      margin-top: 10rem;
      flex-direction: row;
  }

  .main-content {
    min-height: 100vh;
    margin-top: 0rem;
  }

  .navbar {
    padding: 0.5rem 1rem;
  }
}

@media (min-width: 992px) {
  .top-nav-rhs {
    right: 2rem;
  }
}

@media (max-width: 450px) {
  h1 {
    font-size: 2.5rem !important;
  }
  
  h2 {
    font-size: 2rem !important;
  } 
  
  h3 {
    font-size: 1.5rem !important;
  }

  h4, h5, h6 {
    font-size: 1.25rem !important;
  }

  .small-hover-box__container {
    min-height: 15rem !important;
  }

  .info-box {
    p {
      font-size: 1rem !important;
    }
  }

  #getting-started-section {
    .contacts-container .contact-card .card-icon {
        margin-bottom: 0 !important;
      }
      
    .section-container {
      margin-bottom: 1rem !important;

      .title {
        font-size: 1.8rem !important;
      }
    }
  }
}



.dropdown-item:hover{
  background-color: white !important;
  width : auto
}