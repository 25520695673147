#question h2{
  /* font-family: bigmoore, serif; */
  font-weight: 700;
}

#question
{
  font-family: sofia-pro, sans-serif;
}

#question h4{
  font-family: sofia-pro;
  font-size: 1.25rem;
  margin-bottom: 3rem;
}

/* #question .btn{
  margin-top: 3rem;
  background-color: #000000;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.05rem;
  font-family: sofia-pro;
  margin-bottom: 2rem;
  text-transform: uppercase;
} */

.fade-in {
  -webkit-animation: fadein 0.5s linear forwards;
  animation: fadein 0.5s linear forwards;
  opacity: 1;
}

@-webkit-keyframes fadein {
0% { opacity: 0; }
50% { opacity: 0.5; }
100% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0.5; }
  50% { opacity: 0.75; }
  100% { opacity: 1; }
}

.fade-out {
  -webkit-animation: fadeout 0.1s linear forwards;
  animation: fadeout 0.1s linear forwards;
  opacity: 0;
}

@-webkit-keyframes fadeout {
0% { opacity: 1; }
50% { opacity: 0.75; }
100% { opacity: 0.5; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 0; }
}

#question .css-1hve7j5{
  display: flex;
  justify-content: center;
  height: 50px;
}

/* #question {
  transition:opacity ease-in-out 2s;
} */
/* .fade-out {
  opacity:0;  
}
.fade-in {
  opacity:1;
} */