.back_next_button {
	border: 2px solid var(--theme-color-1) !important;
	background-color: #fff !important;
	border-radius: 2rem;
	color: #000;
	font-family: rand light;
	text-transform: none;
	margin: auto;
	margin-top: 1rem;
	float: left;
	padding-left: 4rem;
	padding-right: 4rem;
	&:hover {
		border: 2px solid var(--theme-color-1) !important;
		background-color: var(--theme-color-1) !important;
		color: #fff;
	}
}
.upload_files {
	&:hover {
		border: 2px solid var(--theme-color-1) !important;
		background-color: var(--theme-color-1) !important;
		color: #fff !important;
	}
}

.progress-bar{ 
	background: #fff;
	background-color: var(--theme-color-1);
 	width: 100%;
 }