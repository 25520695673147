body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'rand black italic';
  src: url("./assets/fonts/Rand-Black-Italic.otf");
}
@font-face {
  font-family: 'rand black';
  src: url("./assets/fonts/Rand-Black.otf");
}
@font-face {
  font-family: 'rand bold italic';
  src: url("./assets/fonts/Rand-Bold-Italic.otf");
}
@font-face {
  font-family: 'rand bold';
  src: url("./assets/fonts/Rand-Bold.otf");
}
@font-face {
  font-family: 'rand heavy italic';
  src: url("./assets/fonts/Rand-Heavy-Italic.otf");
}
@font-face {
  font-family: 'rand heavy';
  src: url("./assets/fonts/Rand-Heavy.otf");
}

@font-face {
  font-family: 'rand italic';
  src: url("./assets/fonts/Rand-Italic.otf");
}
@font-face {
  font-family: 'rand light italic';
  src: url("./assets/fonts/Rand-Light-Italic.otf");
}
@font-face {
  font-family: 'rand light';
  src: url("./assets/fonts/Rand-Light.otf");
}
@font-face {
  font-family: 'rand medium italic';
  src: url("./assets/fonts/Rand-Medium-Italic.otf");
}
@font-face {
  font-family: 'rand medium';
  src: url("./assets/fonts/Rand-Medium.otf");
}
@font-face {
  font-family: 'rand regular';
  src: url("./assets/fonts/Rand-Regular.otf");
}
@font-face {
  font-family: 'rand thin italic';
  src: url("./assets/fonts/Rand-Thin-Italic.otf");
}
@font-face {
  font-family: 'rand thin';
  src: url("./assets/fonts/Rand-Thin.otf");
}

p, div, button, span
{
    font-family: 'rand regular';
}

h1, h2, h3
{
    font-family: 'rand heavy';
}

h4, h5, h6
{
    font-family: 'rand bold';
}

.navbar-brand
{
    font-family: 'rand bold';
  
}

.makeStyles-root-1 * {
  font-family: 'rand regular' !important;
}


