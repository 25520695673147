// @font-face {
//     font-family: 'rand regular';
//     src: url("https://storage.googleapis.com/home-page-fonts/Rand-Regular.otf");
// }

@font-face {
    font-family: 'rand black italic';
    src: url("../assets/fonts/Rand-Black-Italic.otf");
}
@font-face {
    font-family: 'rand black';
    src: url("../assets/fonts/Rand-Black.otf");
}
@font-face {
    font-family: 'rand bold italic';
    src: url("../assets/fonts/Rand-Bold-Italic.otf");
}
@font-face {
    font-family: 'rand bold';
    src: url("../assets/fonts/Rand-Bold.otf");
}
@font-face {
    font-family: 'rand heavy italic';
    src: url("../assets/fonts/Rand-Heavy-Italic.otf");
}
@font-face {
    font-family: 'rand heavy';
    src: url("../assets/fonts/Rand-Heavy.otf");
}

@font-face {
    font-family: 'rand italic';
    src: url("../assets/fonts/Rand-Italic.otf");
}
@font-face {
    font-family: 'rand light italic';
    src: url("../assets/fonts/Rand-Light-Italic.otf");
}
@font-face {
    font-family: 'rand light';
    src: url("../assets/fonts/Rand-Light.otf");
}
@font-face {
    font-family: 'rand medium italic';
    src: url("../assets/fonts/Rand-Medium-Italic.otf");
}
@font-face {
    font-family: 'rand medium';
    src: url("../assets/fonts/Rand-Medium.otf");
}
@font-face {
    font-family: 'rand regular';
    src: url("../assets/fonts/Rand-Regular.otf");
}
@font-face {
    font-family: 'rand thin italic';
    src: url("../assets/fonts/Rand-Thin-Italic.otf");
}
@font-face {
    font-family: 'rand thin';
    src: url("../assets/fonts/Rand-Thin.otf");
}

p
{
    font-family: 'rand regular';
}

h1, h2, h3
{
    font-family: 'rand heavy';
}

h4, h5, h6
{
    font-family: 'rand bold';
}

.navbar-brand
{
    font-family: 'rand bold';
  
}


$h2-size-laptop: 3rem;
$h3-size-laptop: 2rem;
$h4-size-laptop: 1.5rem;

$primary-background: #F6F6F6; // base color of the background - shade of grey
$inputs-border-color: #5714ac; // border color for inputs 
$unselected-inputs-border-color: rgba(87,20,172, 0.4); // border color for inputs 
$box-shadow: 2px 2px 6px var(--box-shadow);;
$primary-color: #5714ac;
$secondary-color: #FDB7AE;

:root
{
    --theme-color-1: #5714ac;
    --theme-color-2: rgba(87, 20, 172, 0.3);
    --theme-color-3: #9E83BF;
    --theme-color-with-opacity: rgba(158, 131, 191, 0.3);
    --theme-color-1-hex: #5714ac;

    --theme-color: #9E83BF;
    --primary-background: #F6F6F6; // base color of the background - shade of grey
    --secondary-background: #FFFFFF; // secondary color of the background - shade of grey
    --primary-text-color: #000000; // base color of the text - shade of grey
    --secondary-text-color: rgba(0, 0, 0, 0.4); // secondary color of the background - shade of grey
    --inputs-border-color: #5714ac; // border color for inputs 
    --unselected-inputs-border-color: rgba(87,20,172, 0.4); // border color for inputs 
    --box-shadow: 2px 2px 6px rgba(0,0,0,.16);
    --primary-color: #5714ac;
    --secondary-color: #FDB7AE;
    --primary-button-background: #FFFFFF;
    --secondary-button-background: #FFFFFF;
    --footer-background:#F6F6F6;
    --box-shadow: rgba(0, 0, 0, 0.1);
}
