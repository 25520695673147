@import "../../stylesheets/constants";
$subtitle-color: #707070;



.header-text{
  padding-bottom: 2rem;
}

.header-text h4{
  font-size: $h4-size-laptop;
  font-weight: bold;
  color: var(--primary-text-color);
}

.header-text h2{
  font-size: $h2-size-laptop;
  font-weight: bold;
  line-height: unset;
  color: var(--primary-text-color);
}