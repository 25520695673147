
.color-select-button__container {
  margin-left: 0.5rem;
  // margin-bottom: 0.5rem;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .color-select-button {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    border: none;

    &.wheel {

      img {
        height: 15px;
        width: 15px;
        margin-bottom: 1rem;
      }
    }


    &:focus {
      outline: none;
    }
  }
}

.color-wheel_modal {
  position: fixed;
  display: flex;
  top: 0; right: 0; left: 0; bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, .3);

  &.show {
    visibility: visible;
  }

  &.hide {
    visibility: hidden;
    overflow: hidden;
    top: -9999px;
    left: -9999px;
  }
}

@media (max-width: 450px) {
  .color-select-button__container {
    border: none !important;
  }

  .color-select-button.color {
    display: none;
  }

  .color-select-button.wheel {
      height: 25px;
      width: 25px;
      
      img {
        height: 25px !important;
        width: 25px !important;
        margin-bottom: 0 !important;
      }
  }

}