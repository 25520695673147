/* .stepper-container{
    margin: 40px auto;
    width: 60%;
    flex-direction: row;
} */

.stepper-wrapper {
    display        : flex;
    justify-content: space-between;

}
.stepper-wrapper .step-wrapper {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.stepper-wrapper .step-number {
    border-radius: 80%;
    border: 1px solid gray;
    background-color: white;
    width: 30px;
    height: 30px;
    /* padding: 3px; */
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    z-index: 1;
   
}

.stepper-wrapper .divider-line-after {
    height: 1px;
    background-color: gray;
    position: absolute;
    top: 33%;
    left: 50%;
    width: 50%;

}

.stepper-wrapper .divider-line-before {
    height: 1px;
    background-color: gray;
    position: absolute;
    top: 33%;
    left: 0%;
    width: 50%;

}

.stepper-wrapper .step-label {
    color: grey;
}

.step-number-active {
    background-color: var(--theme-color-1) !important;
    color: #ffffff;
}

.step-number-disabled {
    border: 1px solid grey;
}

.step-number-completed {
    background : url("../images/icons/selected.svg") no-repeat;
    color: #ffffff;
    background-color:  var(--theme-color-1) !important;

}

.step-label-active{
    font-weight: bold;
    color: black !important;
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--theme-color-1);
    fill: none;
    animation: stroke .6s $curve forwards;
  }
  